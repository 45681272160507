import { createContext, useEffect, useReducer, ReactElement } from "react"
import jwtDecode from "jwt-decode"
import { LOGIN, LOGOUT } from "store/reducers/actions"
import authReducer from "store/reducers/auth"
import Loader from "components/Loader"
import axios from "utils/axios"
import { KeyedObject } from "types/root"
import { AuthProps, JWTContextType, UserProfile } from "types/auth"

// constant
const initialState: AuthProps = {
  isLoggedIn: false,
  isInitialized: false,
  scoutingAccess: false,
  offersAccess: false,
  licensingAccess: false,
  simenaAccess: false,
  user: null
}

const verifyToken: (st: string) => boolean = (serviceToken) => {
  if (!serviceToken) {
    return false
  }
  const decoded: KeyedObject = jwtDecode(serviceToken)

  /**
   * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
   */
  return decoded.exp > Date.now() / 1000
}

const setSession = (serviceToken?: string | null) => {
  if (serviceToken) {
    localStorage.setItem("serviceToken", serviceToken)
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`
  } else {
    localStorage.removeItem("serviceToken")
    delete axios.defaults.headers.common.Authorization
  }
}

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext<JWTContextType | null>(null)

export const JWTProvider = ({ children }: { children: ReactElement }) => {
  const [state, dispatch] = useReducer(authReducer, initialState)

  const init = async () => {
    try {
      const serviceToken = localStorage.getItem("serviceToken")
      if (serviceToken && verifyToken(serviceToken)) {
        setSession(serviceToken)
        try {
          const response = await axios.get("/me/")
          const user = response.data as UserProfile

          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              scoutingAccess: user.scouting_access,
              offersAccess: user.offers_access,
              licensingAccess: user.licensing_access,
              simenaAccess: user.simena_access,
              user
            }
          })
        } catch (ex) {
          setSession(null)
          dispatch({
            type: LOGOUT
          })
        }
      } else {
        setSession(null)
        dispatch({
          type: LOGOUT
        })
      }
    } catch (err) {
      setSession(null)
      dispatch({
        type: LOGOUT
      })
    }
  }

  useEffect(() => {
    init().then()
  }, [])

  const authenticate = async (username: string, password: string, ipAddress: string, codeEnabled: boolean) => {
    const response = await axios.post("/authentication/", {
      username,
      password,
      ip_address: ipAddress,
      code_enabled: codeEnabled,
      platform: "simena"
    })
    if (!response.data.success) {
      return response.data
    }

    if (response.data.success && !response.data.user) {
      return response.data
    }

    const { serviceToken, user } = { serviceToken: response.data.user.token, user: response.data.user as UserProfile }
    setSession(serviceToken)
    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        scoutingAccess: user.scouting_access,
        offersAccess: user.offers_access,
        licensingAccess: user.licensing_access,
        simenaAccess: user.simena_access,
        user
      }
    })

    return response.data
  }

  const codeValidation = async (
    username: string,
    password: string,
    name: string,
    country: string,
    code: string,
    resetPassword: boolean
  ) => {
    const response = await axios.post("/code-validation/", {
      username,
      password,
      name,
      code,
      country,
      platform: "simena",
      reset_password: resetPassword
    })
    if (!response.data.success) {
      return false
    }

    const { serviceToken, user } = { serviceToken: response.data.user.token, user: response.data.user as UserProfile }
    setSession(serviceToken)
    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        scoutingAccess: user.scouting_access,
        offersAccess: user.offers_access,
        licensingAccess: user.licensing_access,
        simenaAccess: user.simena_access,
        user
      }
    })

    return true
  }

  const loginSpotify = async (code: string) => {
    const response = await axios.post(`/spotify/?code=${code}`, null, {
      withCredentials: true
    })

    const { serviceToken, user } = { serviceToken: response.data.token, user: response.data as UserProfile }
    setSession(serviceToken)
    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        scoutingAccess: user.scouting_access,
        offersAccess: user.offers_access,
        licensingAccess: user.licensing_access,
        simenaAccess: user.simena_access,
        user
      }
    })
  }

  const logout = () => {
    setSession(null)
    dispatch({ type: LOGOUT })
  }

  const forgotPassword = async (username: string) => {
    const response = await axios.post("/forgot-password/", { username, platform: "simena", ip_address: "127.0.0.1" })
    return response.data.success
  }

  const updateProfile = () => {}

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />
  }

  return (
    <JWTContext.Provider
      value={{ ...state, loginSpotify, authenticate, codeValidation, logout, forgotPassword, updateProfile }}
    >
      {children}
    </JWTContext.Provider>
  )
}

export default JWTContext
