import axios from "axios"

const axiosServices = axios.create({ baseURL: process.env.REACT_APP_BASE_URL })

/*const axiosServices = (path: string, method: "get" | "post" | "put" | "update" | "patch" | "delete") => {
  return axios.create({ baseURL: process.env.REACT_APP_BASE_URL })
}*/

export const controllers: Record<string, AbortController> = {}
/*const controllerId = path.split("?")[0]
const controller = controllers[controllerId]

if (abortable) {
  if (controller) {
    controller.abort()
  }
  controllers[controllerId] = new AbortController()
}*/

axiosServices.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401 && !window.location.href.includes("/login")) {
      window.location.pathname = "/"
    }
    return Promise.reject((error.response && error.response.data) || "Wrong Services")
  }
)

export default axiosServices
