import { FETCH_ARTISTS } from "./actions"
import { PaginatedData } from "../../types/root"
import { Artist, ArtistsActionProps, ArtistsProps } from "../../types/artists"

export const initialArtistsState: ArtistsProps = {
  paginatedArtists: new PaginatedData<Artist>()
}

const tracks = (state = initialArtistsState, action: ArtistsActionProps) => {
  switch (action.type) {
    case FETCH_ARTISTS: {
      const paginatedArtists =
        (action.payload?.paginatedArtists as PaginatedData<Artist>) || new PaginatedData<Artist>()
      return {
        ...state,
        paginatedArtists
      }
    }
    default: {
      return { ...state }
    }
  }
}

export default tracks
