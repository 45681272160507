import { createContext, ReactElement, useReducer } from "react"
import { BlacklistItem, Track, TracksContextType } from "../types/tracks"
import tracksReducer, { initialTracksState } from "../store/reducers/tracks"
import axios from "utils/axios"
import { FETCH_BLACKLIST, FETCH_TRACKS } from "../store/reducers/actions"
import { mapPaginatedDataToCamelCase } from "../utils/caseTransformation"
import { PaginatedData } from "types/root"
import { getFilterText } from "../utils"

const TracksContext = createContext<TracksContextType | null>(null)

export const TracksProvider = ({ children }: { children: ReactElement }) => {
  const [state, dispatch] = useReducer(tracksReducer, initialTracksState)

  const fetchTracks = async (
    limit: number,
    offset: number,
    filter?: any,
    orderBy?: string,
    search?: string,
    albumId?: number,
    artistId?: number
  ) => {
    const response = await axios(
      `/tracks/?limit=${limit}&offset=${offset}${getFilterText(filter)}${orderBy ? `&order_by=${orderBy}` : ""}${
        search ? `&search=${search}` : ""
      }${albumId ? `&album_id=${albumId}` : ""}${artistId ? `&artist_id=${artistId}` : ""}`,
      { method: "get" }
    )

    dispatch({
      type: FETCH_TRACKS,
      payload: { ...state, paginatedTracks: mapPaginatedDataToCamelCase<PaginatedData<Track>>(response.data) }
    })
  }

  const fetchBlacklist = async (limit: number, offset: number, search?: string) => {
    const response = await axios(`/black-list/?limit=${limit}&offset=${offset}${search ? `&search=${search}` : ""}`, {
      method: "get"
    })

    dispatch({
      type: FETCH_BLACKLIST,
      payload: {
        ...state,
        paginatedBlacklist: mapPaginatedDataToCamelCase<PaginatedData<BlacklistItem>>(response.data)
      }
    })
  }

  return <TracksContext.Provider value={{ ...state, fetchTracks, fetchBlacklist }}>{children}</TracksContext.Provider>
}

export default TracksContext
