import React, { lazy } from "react"
import Loadable from "components/Loadable"
import AuthGuard from "utils/route-guard/AuthGuard"
import SharedMainLayout from "../layout/shared/MainLayout"
import SharedCommonLayout from "../layout/shared/CommonLayout"

const SharedRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: (
        <AuthGuard prefix="">
          <SharedMainLayout />
        </AuthGuard>
      ),
      children: []
    },
    {
      path: "/maintenance",
      element: <SharedCommonLayout />,
      children: []
    }
  ]
}

export default SharedRoutes
