import {FormattedMessage} from "react-intl"
import {Profile2User, Music, SearchFavorite, Personalcard, Graph} from "iconsax-react"
import {NavItemType} from "types/menu"

const icons = {
  search: SearchFavorite,
  personal: Personalcard,
  results: Graph,
  artists: Profile2User,
  tracks: Music
}

const sharedNavItems: NavItemType = {
  id: "navigation",
  title: <FormattedMessage id="navigation" />,
  type: "group",
  children: [
    {
      id: "artists",
      title: <FormattedMessage id="artists" />,
      type: "item",
      icon: icons.artists,
      url: "/l/artists"
    },
    {
      id: "tracks",
      title: <FormattedMessage id="tracks" />,
      type: "item",
      icon: icons.tracks,
      url: "/l/tracks"
    }
  ]
}

export default sharedNavItems
