import { createContext, ReactElement, useReducer } from "react"
import axios from "utils/axios"
import { FETCH_COMPANIES, FETCH_COMPANY_BREAKDOWNS } from "../store/reducers/actions"
import { mapPaginatedDataToCamelCase } from "../utils/caseTransformation"
import { PaginatedData } from "types/root"
import { CompaniesContextType, Company, CompanyBreakDown } from "types/companies"
import companiesReducer, { initialCompanyState } from "store/reducers/companies"

const CompaniesContext = createContext<CompaniesContextType | null>(null)

export const CompaniesProvider = ({ children }: { children: ReactElement }) => {
  const [state, dispatch] = useReducer(companiesReducer, initialCompanyState)

  const fetchCompanyList = async (limit: number, offset: number, search?: string) => {
    const response = await axios(`/companies/?limit=${limit}&offset=${offset}${search ? `&search=${search}` : ""}`, {
      method: "get"
    })

    dispatch({
      type: FETCH_COMPANIES,
      payload: {
        ...state,
        paginatedCompanyList: mapPaginatedDataToCamelCase<PaginatedData<Company>>(response.data)
      }
    })
  }

  const fetchCompanyBreakdownList = async (
    limit: number,
    offset: number,
    search?: string,
    companyName?: string,
    companyId?: number
  ) => {
    const companyNameFilter = companyName ? `&company_name=${companyName}` : ""
    const searchFilter = search ? `&search=${search}` : ""
    const companyIdFilter = companyId ? `&company_id=${companyId}` : ""
    const response = await axios(
      `/company/breakdown/?limit=${limit}&offset=${offset}${searchFilter}${companyNameFilter}${companyIdFilter}`,
      {
        method: "get"
      }
    )

    dispatch({
      type: FETCH_COMPANY_BREAKDOWNS,
      payload: {
        ...state,
        paginatedCompanyBreakdownList: mapPaginatedDataToCamelCase<PaginatedData<CompanyBreakDown>>(response.data)
      }
    })
  }

  return (
    <CompaniesContext.Provider value={{ ...state, fetchCompanyList, fetchCompanyBreakdownList }}>
      {children}
    </CompaniesContext.Provider>
  )
}

export default CompaniesContext
