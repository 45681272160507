import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"

// project-imports
import { LICENSING_DEFAULT_PATH, OFFERS_DEFAULT_PATH, SCOUTING_DEFAULT_PATH } from "config"
import useAuth from "hooks/useAuth"

// types
import { GuardProps } from "types/auth"

// ==============================|| GUEST GUARD ||============================== //

const GuestGuard = ({ children, prefix }: GuardProps) => {
  const { isLoggedIn, scoutingAccess, licensingAccess, offersAccess } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    /*if (prefix === "/o" && !location.pathname.includes("login")) {
      return
    }

    if (isLoggedIn) {
      let defaultPath = "/"
      if (prefix === "/") {
        defaultPath = "/navigation"
      } else if (scoutingAccess && prefix === "/s") {
        defaultPath = SCOUTING_DEFAULT_PATH
      } else if (offersAccess && prefix === "/o") {
        defaultPath = OFFERS_DEFAULT_PATH
      } else if (licensingAccess && prefix === "/l") {
        defaultPath = LICENSING_DEFAULT_PATH
      }
      navigate(location?.state?.from ? location?.state?.from : defaultPath, {
        state: {
          from: ""
        },
        replace: true
      })
    }*/
    // }, [isLoggedIn, navigate, location])
  }, [])

  return children
}

export default GuestGuard
