// types
import { DefaultConfigProps, MenuOrientation, ThemeDirection, ThemeMode } from "types/config"

// ==============================|| THEME CONSTANT ||============================== //

export const twitterColor = "#1DA1F2"
export const facebookColor = "#3b5998"
export const linkedInColor = "#0e76a8"

export const LICENSING_DEFAULT_PATH = "/l/home"
export const SCOUTING_DEFAULT_PATH = "/s/home"
export const OFFERS_DEFAULT_PATH = "/o/home"
export const HORIZONTAL_MAX_ITEM = 6
export const DRAWER_WIDTH = 280
export const MINI_DRAWER_WIDTH = 90
export const HEADER_HEIGHT = 74

// ==============================|| THEME CONFIG ||============================== //

const config: DefaultConfigProps = {
  fontFamily: `Inter var`,
  i18n: "en",
  menuOrientation: MenuOrientation.VERTICAL,
  menuCaption: true,
  miniDrawer: false,
  container: false,
  mode: ThemeMode.LIGHT,
  presetColor: "default",
  themeDirection: ThemeDirection.LTR,
  themeContrast: false
}

export default config
