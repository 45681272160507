import { useRoutes } from "react-router-dom"

// project-imports
import SharedLoginRoutes from "./SharedLoginRoutes"
import SharedRoutes from "./SharedRoutes"

// ==============================|| ROUTES RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    SharedLoginRoutes,
    SharedRoutes
  ])
}
