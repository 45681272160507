import { useMediaQuery, useTheme } from "@mui/material"
import ProfileCard from "./ProfileCard"
import Navigation from "./Navigation"
import SimpleBar from "components/third-party/SimpleBar"
import { useSelector } from "store"

// ==============================|| DRAWER CONTENT ||============================== //

const DrawerContent = () => {
  const theme = useTheme()
  const matchDownMD = useMediaQuery(theme.breakpoints.down("lg"))

  const { drawerOpen } = useSelector((state) => state.menu)

  return (
    <SimpleBar
      sx={{
        "& .simplebar-content": {
          display: "flex",
          flexDirection: "column"
        }
      }}
    >
      <>
        <span></span>
      </>
    </SimpleBar>
  )
}

export default DrawerContent
