import {lazy, Suspense, useEffect} from "react"

import { Outlet } from "react-router-dom"

// material-ui
import { styled } from "@mui/material/styles"
import LinearProgress, { LinearProgressProps } from "@mui/material/LinearProgress"
import useConfig from "../../../hooks/useConfig"

const Header = lazy(() => import("./Header"))
const FooterBlock = lazy(() => import("./FooterBlock"))

// ==============================|| LOADER - WRAPPER ||============================== //

const LoaderWrapper = styled("div")(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: 2001,
  width: "100%",
  "& > * + *": {
    marginTop: theme.spacing(2)
  }
}))

export interface LoaderProps extends LinearProgressProps {}

const Loader = () => (
  <LoaderWrapper>
    <LinearProgress color="primary" />
  </LoaderWrapper>
)

// ==============================|| MINIMAL LAYOUT ||============================== //

const SharedCommonLayout = ({ layout = "blank" }: { layout?: string }) => {
  const { onChangePresetColor } = useConfig()

  useEffect(() => {
    onChangePresetColor("theme8")

    if (!localStorage.getItem("theme-color")) {
      localStorage.setItem("theme-color", "light")
    }
  }, [])


  return (
    <>
      {(layout === "landing" || layout === "simple") && (
        <Suspense fallback={<Loader />}>
          <Header layout={layout} />
          <Outlet />
          <FooterBlock isFull={layout === "landing"} />
        </Suspense>
      )}
      {layout === "blank" && <Outlet />}
    </>
  )
}

export default SharedCommonLayout
