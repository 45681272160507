import { Typography } from "@mui/material"
import { Stack } from "@mui/system"
import logo from "../../assets/images/logo.png"

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg'
 * import logo from 'assets/images/logo.svg'
 *
 */

const LogoMain = ({ reverse, text, ...others }: { reverse?: boolean, text: string }) => {
  //const theme = useTheme()
  return (
    <Stack>
      <img src={logo} alt="icon logo" width="200" />
      <Typography color={"primary.light"} ml={20}>
          {text}
      </Typography>
    </Stack>
  )
}

export default LogoMain
